import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'members',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'members/places',
    loadChildren: () => import('./places/places.module').then( m => m.PlacesPageModule)
  },
  {
    path: 'members/tickets',
    loadChildren: () => import('./tickets/tickets.module').then( m => m.TicketsPageModule)
  },
  {
    path: 'members/places-map',
    loadChildren: () => import('./places-map/places-map.module').then( m => m.PlacesMapPageModule)
  },
  {
    path: 'members/places-menu',
    loadChildren: () => import('./places-menu/places-menu.module').then( m => m.PlacesMenuPageModule)
  },
  {
    path: 'members/places-picture',
    loadChildren: () => import('./places-picture/places-picture.module').then( m => m.PlacesPicturePageModule)
  },
  {
    path: 'members/places-sku',
    loadChildren: () => import('./places-sku/places-sku.module').then( m => m.PlacesSkuPageModule)
  },
  {
    path: 'members/places-pop',
    loadChildren: () => import('./places-pop/places-pop.module').then( m => m.PlacesPopPageModule)
  },
  {
    path: 'members/places-conquered',
    loadChildren: () => import('./places-conquered/places-conquered.module').then( m => m.PlacesConqueredPageModule)
  },
  {
    path: 'members/places-prices',
    loadChildren: () => import('./places-prices/places-prices.module').then( m => m.PlacesPricesPageModule)
  },
  {
    path: 'members/places-competition',
    loadChildren: () => import('./places-competition/places-competition.module').then( m => m.PlacesCompetitionPageModule)
  },
  {
    path: 'members/tickets',
    loadChildren: () => import('./tickets/tickets.module').then( m => m.TicketsPageModule)
  },
  {
    path: 'members/tickets-new',
    loadChildren: () => import('./tickets-new/tickets-new.module').then( m => m.TicketsNewPageModule)
  },   {
    path: 'members/places-new',
    loadChildren: () => import('./places-new/places-new.module').then( m => m.PlacesNewPageModule)
  }
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
